.slider {
  overflow: unset !important;
}

.image-carousel {
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.image-carousel .carousel-root,
.image-carousel .carousel,
.image-carousel .slider-wrapper,
.image-carousel .slider
{
  height: 100%;
}

.image-carousel .details {
  margin: 8px 0px;
}

.image-carousel .row1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
}

.image-carousel .subRow1 {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.image-carousel .location {
  font-size: 15px;
  font-weight: 600;
  margin: 3px 0px;
}

.image-carousel .numRating {
  font-weight: 400;
  font-size: 15px;
  margin-left: 4px;
}

.image-carousel .info {
  font-weight: 400;
  color: #717171;
  margin: 0px;
  margin-bottom: 3px;
}

.image-carousel .price {
  font-size: 15px;
  font-weight: 600;
}

.image-carousel .type {
  font-size: 15px;
  font-weight: 400;
}

.image-carousel .lastRow {
  margin-top: 8px;
}

.image-carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-carousel .nextArrow,
.image-carousel .prevArrow {
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  display: none;
}

.image-carousel .prevArrow {
  left: 0;
}

.image-carousel .nextArrow {
  right: 0;
}

.image-carousel .carousel-root:focus-visible .prevArrow,
.image-carousel:hover .prevArrow {
  display: flex;
}

.image-carousel .carousel-root:focus-visible .nextArrow,
.image-carousel:hover .nextArrow {
  display: flex;
}

.image-carousel .carousel-root:focus-visible .prevArrow,
.image-carousel .prevArrow:hover,
.image-carousel .carousel-root:focus-visible .nextArrow,
.image-carousel .nextArrow:hover {
}

.image-carousel .indicatorsStyle {
  background: white;
  width: 6px;
  height: 6px;
  display: inline-block;
  margin: 0 3px;
  border-radius: 50%;
  opacity: 80%;
}
.image-carousel .selectedIndicatorStyle {
  background: white;
  width: 6px;
  height: 6px;
  display: inline-block;
  margin: 0 3px;
  border-radius: 50%;
}

.image-carousel .heartIcon {
  position: absolute;
  z-index: 30;
  right: 8px;
  top: 8px;
}

.image-carousel .control-dots {
  background: transparent;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 80%);
  margin: 0;
}
