.DefaultBackground {
  position: absolute;
  top: 9px;
  z-index: 19;

  width: 100%;
  left: 0px;
  height: 4px;

  background-color: var(--light-grey);
}

.handleContainer {
  position: absolute;
  top: 0px;
  z-index: 40;
  /* 20px is the width of the handle */
  width: calc(100% - 20px);
}

.DefaultHandle_handle {
  width: 20px;
  height: 20px;
  padding-right: 0px;
  padding-left: 0px;

  border-radius: 3px;

  background-color: var(--blue);
}

.DefaultProgressBar_progressBar {
  position: absolute;
  top: 0px;
  z-index: 20;

  /* 20px is the width of the handle */
  width: calc(100% - 20px);
  left: 10px;
  height: 4px;

  background-color: var(--brand-blue);
}
