.mapboxgl-marker path[fill] {
  fill: var(--blue);
}

.mapboxgl-popup-content {
  padding: 0;
  border-radius: 0.625rem;
}

.mapboxgl-popup-content .card_component {
  max-width: 100%;
  border-bottom: 0;
  z-index: 2;
}

@media (max-width: 767px) {
  .mapboxgl-popup-content .image-carousel .nextArrow,
  .mapboxgl-popup-content .image-carousel .prevArrow {
    display: flex;
  }
}

.mapboxgl-popup-tip {
  margin-top: -1px;
}

.mapboxgl-popup-close-button {
  top: -11px !important;
  right: -11px !important;
  background-color: hsla(208.00000000000003, 0.00%, 100.00%, 1.00) !important;
  border-radius: 20px !important;
}


.mapboxgl-control-container > * {
  z-index: 3000;
}
